import React, {Component} from 'react';
import './slick-slider.scss';

class SlickSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selItem: 0,
            mouseX: 0,
            windowWidth: window.innerWidth,
            isClick: false
        };
    }

    updateDimensions = () => this.setState({windowWidth: window.innerWidth});

    onChangeSelItem = (type) => {
        if(type==='prev' && this.state.selItem > 0) {
            this.setState({selItem: this.state.selItem-1});
        } else if(type==='next' && this.state.selItem < this.props.item.length - 1) {
            this.setState({selItem: this.state.selItem+1});
        }
    };

    onMouseDown(e) {
        this.setState({
            mouseX: e.touches[0].pageX,
            isClick: true
        });
    }

    onMouseUp(e) {
        this.setState({
            mouseX: 0,
            isClick: false
        });
    }

    onMouseMove(e) {
        if(this.state.isClick === true && this.state.mouseX!==0) {
            var mouseX = e.touches[0].pageX;
            var diff = this.state.mouseX - mouseX;
            if(diff>100 && this.state.selItem < this.props.item.length - 1) {this.setState({selItem: this.state.selItem+1, mouseX: mouseX}); console.log(diff)}
            else if(diff<-100 && this.state.selItem > 0) {this.setState({selItem: this.state.selItem-1, mouseX: mouseX}); console.log(diff)}
        }
    }

    render() {
        const {selItem} = this.state;
        // console.log(this.state)
        return (
        // <div className="embedding_slick-slider" onMouseDown={this.onMouseDown.bind(this)} onMouseMove={this.onMouseMove.bind(this)} onMouseUp={this.onMouseUp.bind(this)}>
            <div
                className="embedding_slick-slider"
                onTouchStart={this.onMouseDown.bind(this)}
                onTouchMove={this.onMouseMove.bind(this)}
                onTouchEnd={this.onMouseUp.bind(this)}
            >
                <button className={"embedding_slick-slider-arrow-prev" + (selItem < 1 ? " disabled" : "")} onClick={() => this.onChangeSelItem('prev')}/>
                <div className="embedding_slick-slider-wrapper" style={{transform: `translateX(-${selItem * 324}px)`}}>
                    {
                        this.props.item.map((val, idx) => (
                            <div key={idx}>
                                <p className="embedding_slick-slider-title">{val.title}</p>
                                <p className="embedding_slick-slider-content">{val.content}</p>
                            </div>
                        ))
                    }
                </div>
                <button className={"embedding_slick-slider-arrow-next" + (selItem >= this.props.item.length-1 ? " disabled" : "")} onClick={() => this.onChangeSelItem('next')}/>
                
                <div className="embedding_slick-slider-progress-wrapper">
                    {
                        this.props.item.map((val, idx) => (
                            <button key={idx} className={"embedding_slick-slider-progress" + (selItem >= idx ? " active" : "")} onClick={() => this.setState({selItem: idx})}/>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default SlickSlider;