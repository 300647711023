import React, {Component} from 'react';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="web-header">
                <div className="web-header-mobile">
                    <div className="title-bar">
                        <button className="back-btn" onClick={() => window.history.back()}><i className="icon chevron-left" aria-hidden="true"></i></button>
                        <button className="home-btn" onClick={() => window.location.href=process.env.REACT_APP_HOST}><i className="icon home-o" aria-hidden="true"></i></button>
                        <div className="content" id="title-bar">{this.props.title} | 와디즈 리워드 - 크라우드펀딩</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
