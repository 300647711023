import React from 'react';

const FooterInfo = ({onClickFooterInfo}) => {

    return (
        <div className="DialogModal_dialogPortal__1FbT8">
            <div className="DialogModal_dialogOverlay__31vTF DialogModal_basic__1ITvX DialogModal_dialogOverlayAfterOpen__2kQjN">
                <div className="EquityRiskModal_dialog-content__1fsES EquityRiskModal_dialog-content-after-open__1fftr" tabindex="-1" role="dialog">
                    <div className="EquityRiskModal_dialog__17NJ2">
                        <div className="EquityRiskModal_modal__2U25_">
                            <div className="EquityRiskModal_contents__1fd-G">
                                <div className="inner-contents">
                                    <article>
                                        <h1>투자 위험 주지 및 고지</h1>
                                        <p>본 위험고지서는 자본시장과 금융투자에 관한 법률(이하 “자본시장법”이라고만 합니다) 제117조의7 제4항에 의하여 귀하가 와디즈플랫폼 주식회사(이하 와디즈플랫폼 (주)”라고만 합니다)에 청약의 주문을 하기 전에 투자의 위험을 사전에 충분히 인지할 수 있도록 와디즈플랫폼 (주)가 귀하에게 교부하는 것입니다.</p>
                                        <ol>
                                            <li>귀하는 투자대상인 금융투자상품은 자본시장법에 따른 “증권”에 해당하므로 원본손실의 위험성이 있으며, 청약증거금 등 투자한 자금의 원본을 회수할 없음에 따른 손실의 위험이 있음을 이해합니다. 또한 귀하가 예상하거나 기대하는 수익의 일부 또는 전부를 얻지 못할 수 있습니다.</li>
                                            <li>귀하는 와디즈플랫폼 (주)의 홈페이지에 게재(정정)된 모집되는 증권의 발행조건, 발행인의 재무상태가 기재된 서류 및 사업계획서의 내용, 증권의 취득에 따른 투자위험요소 등을 충분히 확인하였으며, 청약의 주문 시 회사는 투자 위험의 고지 등에 관하여 별도로 정한 방법에 의하여 통지하는 내용에 대하여 확인합니다.</li>
                                            <li>귀하는 금번에 발행되는 비상장 증권의 발행목적은 한국거래소의 상장에 있는 것은 아니므로 증권의 환금성에 큰 제약이 있음과 귀하가 예상하는 회수금액에 대한 일부 또는 전부를 회수할 수 없는 위험이 있음을 이해하며, 귀하가 이를 감당할 수 있음을 확인합니다.</li>
                                            <li>귀하는 시장의 상황, 제도의 변경이 있을 수 있으며, 자본시장법 등 관련법규에 근거하여 투자의 한도에 제한이 있는 경우 이를 준수하여야 함을 이해합니다.</li>
                                            <li>귀하는 자본시장법 제117조의10 제7항에 따라 전문투자자(벤처캐피탈 등)에 대한 매도 등 예외적인 경우를 제외하고는 원칙적으로 6개월간 전매가 제한된다는 점을 이해합니다.</li>
                                            <li>귀하는 정부가 투자대상인 증권과 관련하여 게재된 사항이 진실 또는 정확하다는 것을 인정하거나 해당 증권의 가치를 보증 또는 승인한 것이 아니라는 점과 게재된 투자정보에 관한 사항은 청약기간 종료 전에 정정될 수 있음을 확인합니다.</li>
                                            <li>귀하는 청약기간 중에만 한정하여 청약의 철회가 가능하며(청약기간 종료일 이후에는 청약의 철회 불가), 청약기간의 종료 시 모집금액이 발행인이 목표한 모집예정금액의 80% 미달하는 경우 증권의 발행은 취소된다는 점을 이해합니다.</li>
                                            <li>
                                                귀하는 아래 사항들에 대하여도 이해하였음을 확인합니다.
                                                <ol>
                                                    <li>와디즈플랫폼 (주)는 온라인소액증권 청약과 관련하여 별도의 수수료는 징수하지 않습니다.</li>
                                                    <li>발행인이 증권의 발행조건과 관련하여 조기상환조건을 설정한 경우 이에 대한 구체적인 내용을 홈페이지를 통해 반드시 확인하여야 합니다.</li>
                                                    <li>와디즈플랫폼 (주)는 자본시장법상의 온라인소액투자중개업자로서의 지위에서 온라인소액증권발행인과 온라인소액투자중개계약을 체결하여 위 발행인이 발행하는 증권에 대한 청약 거래를 중개하므로, 모집예정금액에 도달한다고 하더라도 직접 증권의 발행하거나 주금을 납입 받지 않습니다.</li>
                                                    <li>청약의 우대차별사유 : 와디즈플랫폼 (주)는 발행인의 요청에 따라 합리적이고 명확한 기준(선착순 등)에 따라 투자자의 자격 등을 제한할 수 있습니다. 이 경우 귀하는 위 기준과 조건에 따라 청약거래에 있어 차별을 받게 될 수 있습니다.</li>
                                                </ol>
                                            </li>
                                        </ol>
                                        <p>위 사항들은 청약의 주문 거래에 수반되는 위험∙제도 및 청약의 주문 거래와 관련하여 귀하가 알아야 할 사항을 간략하게 서술한 것으로 귀하의 위 거래와 관련하여 발생될 수 있는 모든 위험과 중요 사항을 전부 기술한 것은 아닙니다. 따라서 상세한 내용은 와디즈플랫폼 (주) 및 관계법규를 통하여 확인하여야 합니다. 또한 이 고지서는 청약의 주문 관련 법규 등에 우선하지 못한다는 점을 양지하시기 바랍니다.</p>
                                    </article>
                                </div>
                            </div>
                            <button type="button" className="wz button block primary EquityRiskModal_agree-button__3JlhK" onClick={onClickFooterInfo}>모든 내용을 확인하였습니다</button>
                        </div>
                        <button type="button" className="EquityRiskModal_modal-close__1JVB2 icon-close" title="닫기" onClick={onClickFooterInfo}></button>
                    </div>
                </div>
            </div>
        </div>     
    );
}

export default FooterInfo;
