import React from 'react';
import { EmbedSource } from '../../Containers';

const EmbedStory = ({source, sourceOrigin, active}) => {
    return (
        <div className={"embedding_story-wrap" + (active ? ' active' : '')}>
            <h2 className="wz text title">스토리보드</h2>
            <EmbedSource source={source} multiline={true} toastMsg="스토리 보드 임베드 코드가 복사되었습니다."/>
            <div className="embedding_story-embed-wrap" dangerouslySetInnerHTML={{__html: sourceOrigin}}></div>
        </div>
    );
}

export default EmbedStory;