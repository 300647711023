import React, {Component} from 'react';
import Axios from 'axios';
import logo from '../../Assets/Images/wadiz.svg';

import './story.scss';
import { SlickSlider } from '../../Containers';

class Story extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            type: props.match.params.type,
            title: "",
            summary: "",
            imageUrl: "",
            progress: "",
            amount: 0,
            restDay: 0,
            name: "",
            profileImageUrl: "",
            url: "",
            story: "",
            plan: "",
            detail: "",
            point: []
        };

        this.init();
    }

    async init() {
        // console.log(`${process.env.REACT_APP_API_HOST}embed/${this.state.type}/v1/${this.state.id}`)
        const {data} = await Axios.get(`${process.env.REACT_APP_API_HOST}embed/${this.state.type}/v1/${this.state.id}`).then(successData => successData).catch(errorData => errorData);

        if(data) {
            if(!data.data.progress) data.data.progress = 0;
            if(!data.data.amount) data.data.amount = 0;

            if(this.state.type === 'equity') {
                this.setState({
                    title: data.data.title,
                    summary: data.data.summary,
                    imageUrl: data.data.image,
                    progress: data.data.progress,
                    amount: data.data.amount,
                    restDay: data.data.restDay,
                    name: data.data.name,
                    profileImageUrl: data.data.profileImage,
                    url: data.data.url,
                    // story: data.data.story,
                    plan: data.data.plan,
                    detail: data.data.businessDetail,
                    point: [
                        {
                            title: data.data.pointTitle_1,
                            content: data.data.pointContent_1
                        },
                        {
                            title: data.data.pointTitle_2,
                            content: data.data.pointContent_2
                        },
                        {
                            title: data.data.pointTitle_3,
                            content: data.data.pointContent_3
                        },
                    ],
                });
            } else if(this.state.type === 'reward') {
                this.setState({
                    title: data.data.title,
                    summary: data.data.summary,
                    imageUrl: data.data.image,
                    progress: data.data.progress,
                    amount: data.data.amount,
                    restDay: data.data.restDay,
                    name: data.data.name,
                    profileImageUrl: data.data.profileImage,
                    url: data.data.url,
                    story: data.data.story,
                });
            }
        }
    }

    comma = (value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    render() {
        // const {title, summary, imageUrl, progress, amount, name, profileImageUrl, url, story} = this.state;
        const {title, summary, imageUrl, progress, amount, name, profileImageUrl, url, story, plan, detail, point} = this.state;

        
        return (
            <div className="embedding_story-embed">
                <div className="embedding_image" style={{backgroundImage: `url(${imageUrl})`}}></div>
                <hr></hr>
                <div className="embedding_border-wrap">
                    <div className="embedding_information">
                        <div className="left">
                            <span className="embedding_progress">
                                {progress}
                                <em>%</em>
                                <em className="embedding_progress-detail">달성</em>
                            </span>
                        </div>
                        <div className="right">
                            {/* <span className="embedding_state">펀딩성공</span> */}
                            <span className="embedding_amount">{this.comma(amount.toString())}원</span>
                        </div>
                    </div>                
                    <div className="embedding_provider">
                        <i className="embedding_icon" style={{backgroundImage: `url(${profileImageUrl})`}}></i>
                        <span>{name}</span>
                    </div>
                    <div className="embedding_title"><span>{title}</span></div>
                    <div className="embedding_summary"><span>{summary}</span></div>
                    <div className="embedding_button"><button onClick={() => { window.open(url); window.dataLayer.push({ event: 'embed.campaign.shortcut' }); }}>바로가기<i></i></button></div>
                    {/* <div className="embedding_detail" dangerouslySetInnerHTML={{__html: story}}></div> */}
                    {                      
                        (this.state.type==='equity')?(
                            <div className="embedding_detail">
                                <section id="attraction">
                                    <div className="embedding_section">
                                        <div className="embedding_section-title">
                                    {/* <div className="EquityCampaignSection_section__33Dzw"> */}
                                        {/* <div className="EquityCampaignSection_sectionTitle__xJFld"> */}
                                            <p>핵심포인트</p>
                                        </div>
                                        <div className="embedding_section-content">
                                            <SlickSlider item={point}/>
                                        </div>
                                    </div>
                                </section>
                                <section id="plan">
                                    <div className="embedding_section">
                                        <div className="embedding_section-title">
                                            <p>비즈니스 전략</p>
                                        </div>
                                        <div className="embedding_section-content">
                                            <div dangerouslySetInnerHTML={{__html: plan}}/>
                                        </div>
                                    </div>
                                </section>
                                <section id="detail">
                                    <div className="embedding_section">
                                        <div className="embedding_section-title">
                                            <p>상세내용</p>
                                        </div>
                                        <div className="embedding_section-content">
                                            <div dangerouslySetInnerHTML={{__html: detail}}/>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        ):(
                            <div className="embedding_detail" dangerouslySetInnerHTML={{__html: story}}></div>
                        )
                    }

                    <div className="embedding_logo">
                        {/* <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_HOST}><span className="embedding_logo-text">라이프스타일 투자플랫폼</span><img src={logo} alt="wadiz"/></a> */}
                        <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_HOST}><img src={logo} alt="wadiz"/></a>
                    </div>
                </div>
            </div>
        );

    }
}

export default Story;