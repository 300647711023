import React, {Component} from 'react';
import { FooterInfo } from '../../Components';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footerInfo: false,
        };
    }

    onClickFooterInfo = () => this.setState({footerInfo: !this.state.footerInfo});

    render() {
        return (
            <footer id="footer" className="web-footer inner-utils show-actionbar">
                <div className="wrapper">
                    <p className="copyright"><strong>시대를 만듭니다<br/>라이프스타일 투자플랫폼 와디즈</strong><br/>© WADIZ Platform Co., Ltd.</p>
                    <div className="app">
                        <ul>
                            <li><a href="https://itunes.apple.com/kr/app/wadijeu/id1107828621?l=enmt=8" target="_blank" className="icon-apple" rel="noopener noreferrer">iOS 앱</a></li>
                            <li><a href="https://play.google.com/store/apps/details?id=com.markmount.wadiz" target="_blank" className="icon-google-play" rel="noopener noreferrer">안드로이드 앱</a></li>
                        </ul>
                    </div>
                    <div className="platform-info">
                        <p>와디즈플랫폼 (주) <span className="sep">|</span> 대표이사 최동철 <span className="sep">|</span> 사업자등록번호 220-88-37661</p>
                        <p>경기도 성남시 분당구 판교로 242 (삼평동) 판교디지털센터 A동 4층 402호</p>
                        <p className="contact"><strong>와디즈 대표 고객센터</strong> 1661-9056<span className="sep">|</span><a href="mailto:info@wadiz.kr">info@wadiz.kr</a></p>
                        <p className="contact"><strong>W9 멤버십 고객센터</strong> 1811-9090</p>
                        <p className="partner"><strong>광고문의</strong> <a href="mailto:ad@wadiz.kr">ad@wadiz.kr</a> <span className="sep">|</span><a href="https://sites.google.com/a/wadiz.kr/ad/" target="_blank" rel="noopener noreferrer">광고상품보기</a></p>
                        <p className="partner"><strong>파트너 제휴문의</strong><a href="mailto:partner@wadiz.kr">partner@wadiz.kr</a> <span className="sep">|</span> <a href="/web/wpartner/main">파트너페이지</a></p>
                        <p className="partner"><strong>마케팅 제휴/PR 문의</strong><a href="mailto:mktpr@wadiz.kr">mktpr@wadiz.kr</a></p>
                        <p className="yellowid"><a href="https://pf.kakao.com/_pEeEl" target="_blank" rel="noopener noreferrer">Yellow ID @와디즈</a></p>
                        <p><a href="/web/wterms/service_reward">이용약관</a><span className="sep">|</span> <a href="/web/wterms/privacy_reward">개인정보처리방침</a> <span className="sep">|</span> <a href="http://bit.ly/2y5IUe0">수수료 항목</a></p>
                    </div>
                    <blockquote className="equity-warn">
                        <p>투자위험고지</p>
                        <p>비상장기업 투자는 원금 손실의 가능성이 크니 <button type="button" onClick={this.onClickFooterInfo}>투자 위험 안내</button>를 꼭 확인하세요.</p>
                        <p className="">와디즈플랫폼 (주)는 크라우드 펀딩 플랫폼을 제공하는 중개자로 자금을 모집하는 당사자가 아니며, 투자손실의 위험을 보전하거나 보상품 제공을 보장해 드리지 못합니다.</p>
                    </blockquote>
                    <div className="externals">
                        <div className="externals-wrap">
                            <ul>
                                <li><span title="카카오톡 옐로아이디"><i className="icon-kakao" aria-hidden="true"></i></span>
                                    <ul>
                                        <li><a href="http://pf.kakao.com/_MGxgmxl" target="_blank" rel="noopener noreferrer">투자</a></li>
                                        <li><a href="http://pf.kakao.com/_pEeEl" target="_blank" rel="noopener noreferrer">리워드</a></li>
                                    </ul>
                                </li>
                                <li><span title="페이스북"><i className="icon-facebook" aria-hidden="true"></i></span>
                                    <ul>
                                        <li><a href="https://www.wadiz.kr/link/fb_invest" target="_blank" rel="noopener noreferrer">투자</a></li>
                                        <li><a href="https://www.wadiz.kr/link/fb_reward" target="_blank" rel="noopener noreferrer">리워드</a></li>
                                    </ul>
                                </li>
                            </ul>
                            <ul>
                                <li><a href="https://brunch.co.kr/@wadiz" target="_blank" title="브런치" rel="noopener noreferrer"> <i className="icon-brunch" aria-hidden="true"></i></a></li>
                                <li><a href="https://www.instagram.com/wadiz_reward/" target="_blank" title="인스타그램" rel="noopener noreferrer"> <i className="icon-instagram" aria-hidden="true"></i></a></li>
                                <li><a href="http://blog.naver.com/wadiz_crowdfunding" target="_blank" title="네이버 블로그" rel="noopener noreferrer"> <i className="icon-naver-blog" aria-hidden="true"></i></a></li>
                                <li><a href="https://www.youtube.com/channel/UCF1_fHSt6Efemgy5wToafNw/featured" target="_blank" title="유튜브" rel="noopener noreferrer"> <i className="icon-youtube-play" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div role="button" id="intercom-launcher" className="question" title="문의하기">
                    <em id="intercom-launcher-badge" className=""></em>
                </div>
                <button type="button" id="footer-page-top" className="page-top" title="위로 이동"><i className="icon page-top"></i></button> */}
                {this.state.footerInfo && <FooterInfo active={this.state.footerInfo} onClickFooterInfo={this.onClickFooterInfo}/>}
            </footer>
        );
    }
}

export default Footer;
