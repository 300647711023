import React, {Component} from 'react';
import { OneButton } from '../../Components';
// import {toastService} from '../../Services/toast-service';

class EmbedSource extends Component {
    inputBox;

    constructor(props) {
        super(props);
        this.state = {
            isPopup: false,
        };
    }

    copy_to_clipboard = () => {
        this.inputBox.select();
        document.execCommand("Copy");

        // toastService.set(toastMsg);
        this.setState({isPopup: true});
    };

    onPopupConfirm = () => this.setState({isPopup: false});

    render() {
        const {isPopup} = this.state;
        
        return (
            <div className="embedding_source-wrap">
                <div className="wz input">
                    {
                        this.props.multiline ?
                        <textarea ref={(ref) => this.inputBox = ref} value={this.props.source} readOnly></textarea> :
                        <input ref={(ref) => this.inputBox = ref} value={this.props.source} readOnly/>
                    }
                </div>
                <button type="button" className="copy-btn wz button gray" onClick={this.copy_to_clipboard}>복사하기</button>
                {isPopup && <OneButton content={this.props.toastMsg} onConfirm={this.onPopupConfirm}/>}
            </div>
        );
    }
}

export default EmbedSource;