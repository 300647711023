import React from 'react';
import { EmbedSource } from '../../Containers';

const EmbedProjectLink = ({source}) => (
    <div className="embedding_project-link-wrap">
        <h2 className="wz text title">프로젝트 링크</h2>
        <EmbedSource source={source} toastMsg="프로젝트 링크가 복사되었습니다."/>
    </div>
);

export default EmbedProjectLink;