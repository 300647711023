import React, {Component} from 'react';
import {toastService} from '../../Services/toast-service';
import './toast.scss';

class Toast extends Component {
    ani_duration = 400;
    hide_interval = 5000;
    subscription;

    constructor(props) {
        super(props);

        this.state = {
            msg: []
        };
    }

    componentDidMount() {
        this.subscription = toastService.get().subscribe(msg => {
            if(msg) this.setState({msg: [...this.state.msg, msg]});
            else this.setState({msg: []});
            
            setTimeout(() => {
                let tmp_msg = this.state.msg;
                tmp_msg[tmp_msg.length-1] = {text: msg.text, isShow: true};

                this.setState({msg: tmp_msg});
            });
            setTimeout(() => {
                let tmp_msg = this.state.msg;
                const idx = tmp_msg.findIndex(val => val.isShow);
                tmp_msg[idx] = {text: tmp_msg[idx].text, isShow: false};

                this.setState({msg: tmp_msg});
            }, this.hide_interval + this.ani_duration);
                
            setTimeout(() => {
                let tmp_msg = this.state.msg;
                const idx = tmp_msg.findIndex(val => !val.isShow && !val.isDestroy);
                tmp_msg[idx] = {text: tmp_msg[idx].text, isShow: false, isDestroy: true};

                this.setState({msg: tmp_msg});
            }, this.hide_interval + this.ani_duration * 2);
        });
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    render() {
        const {msg} = this.state;

        return (
            <main id="toastWrap">
                {msg.map((val, idx) =>
                    {
                        if(!val.isDestroy) return (<div key={idx} className={"embedding_toast-msg" + (val.isShow ? ' active': '')}>{val.text}</div>)
                        else return false
                    }
                )}
            </main>
        );
    }
}

export default Toast;