import React from 'react';
import { EmbedSource } from '../../Containers';

const EmbedWidget = ({source}) => (
    <div className="embedding_widget-wrap">
        <h2 className="wz text title">위젯</h2>
        <EmbedSource source={source} multiline={true} toastMsg="위젯 임베드 코드가 복사되었습니다."/>
        <div className="embedding_widget-embed-wrap" dangerouslySetInnerHTML={{__html: source}}></div>
    </div>
);

export default EmbedWidget;