import React from 'react';

const OneButton = ({content, onConfirm}) => (
    <div className="DialogModal_dialogPortal__32AHN">
        <div className="DialogModal_dialogOverlay__3tadZ DialogModal_dialogOverlayAfterOpen__3kQ0j">
            <div className="DialogModal_dialogContent__RSFGi DialogModal_dialogContentAfterOpen__2PaVo" tabindex="-1" role="dialog">
                <div className="Dialog_dialogWrap__-c5E0">
                    <div className="Dialog_dialogBox__1F891">
                        <div className="Dialog_dialogTextBox__2gwRw">
                            <div className="Dialog_dialogMessage__1blg3">
                                <p>{content}</p>
                            </div>
                        </div>
                        <div className="Dialog_dialogButtonArea__OXhHv">
                            <button type="button" className="Dialog_dialogButton__2wtDp Dialog_dialogButtonPrimary__10kwR Dialog_dialogButtonReverse__2L89a" onClick={onConfirm}>
                                <span>확인</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default OneButton;