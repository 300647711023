import React from 'react';
import { EmbedSource } from '../../Containers';

const EmbedVideo = ({source, sourceOrigin, onChangeSize, sizeIdx, active, height}) => {
    let customWidth = 0, customHeight = 0;
    const onClickButton = (idx, width) => {
        onChangeSize(idx, width);
        customWidth.value = "";
        customHeight.value = "";
    }

    return (
        <div className={"embedding_video-wrap" + (active ? ' active' : '')}>
            <h2 className="wz text title">동영상</h2>
            <EmbedSource source={source} multiline={true} toastMsg="동영상 임베드 코드가 복사되었습니다."/>
            <div className="embedding_video-content">
                <div className="embedding_video" dangerouslySetInnerHTML={{__html: sourceOrigin}}></div>
                <div className="embedding_size">
                    <div className="embedding_select-size">
                        <span className="wz text caption2">다음의 동영상 사이즈 중 하나를 선택한 후 소스코드를 복사하세요.</span>
                        <div className="embedding_button-wrap">
                            <button type="button" className={'wz button' + (sizeIdx===1 ? ' active' : '')} onClick={() => onClickButton(1, 480)}>480X270</button>
                            <button type="button" className={'wz button' + (sizeIdx===2 ? ' active' : '')} onClick={() => onClickButton(2, 640)}>640X360</button>
                            <button type="button" className={'wz button' + (sizeIdx===3 ? ' active' : '')} onClick={() => onClickButton(3, 800)}>800X450</button>
                        </div>
                    </div>
                    <div className="embedding_custom-size">
                        <span className="wz text caption2">동영상 사이즈 직접 지정</span>
                        <div className="embedding_input-wrap">
                            <div className="wz input">
                                <input
                                    ref={ref => customWidth = ref}
                                    type="text"
                                    name="pseudo-text"
                                    onChange={() => {
                                        onChangeSize(0, customWidth.value, customHeight.value);
                                        if(customWidth.value && !isNaN(customWidth.value) && customWidth.value >= 220) customHeight.value = customWidth.value / 16 * 9;
                                        else customHeight.value = "";
                                    }}/>
                            </div>
                            <span>x</span>
                            <div className="wz input">
                                <input ref={ref => customHeight = ref} type="text" name="pseudo-text" readOnly/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmbedVideo;