import React, {Component} from 'react';
import { EmbedVideo, EmbedStory, EmbedProjectLink, EmbedWidget } from '../../Components';
import { HeaderLarge, HeaderMobile } from '../../Containers';
import { Footer } from '../../Containers';
// import { Toast } from '../../Containers';
import Axios from 'axios';
import './embed-main.scss';
// import './embed-main-leo.scss';

class EmbedMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selTab: 1,
            videoUrl: "",
            videoSource: '<iframe width="{{width}}" height="{{height}}" src="{{url}}" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
            sizeIdx: 1, //embed-video 사이즈 idx
            videoWidth: '480',
            videoHeight: '270',
            storySource: '<iframe width="{{width}}" height="{{height}}" src="{{host}}story/{{type}}/{{id}}" frameBorder="0" title="embedWidget" scrolling="no"></iframe>',
            storyOriginSource: '<object width="{{width}}" height="{{height}}" data="{{host}}story/{{type}}/{{id}}"></object>',
            widgetSource: '<iframe width="252" height="525" src="{{host}}widget/{{type}}/{{id}}" frameBorder="0" title="embedWidget"></iframe>',
            projectLink: "",

            id: props.match.params.id,
            type: props.match.params.type, //equity, reward

            windowWidth: 0,
        };

        this.init();
    }

    async init() {
        const {data} = await Axios.get(`${process.env.REACT_APP_API_HOST}embed/${this.state.type}/v1/${this.state.id}`).then(successData => successData).catch(errorData => errorData);

        if(data) {
            const youtubeReg = /(http|https):\/\/youtu\.be\/([a-zA-Z0-9]+)/g;
            const vimeoReg = /(http|https):\/\/vimeo\.com\/([a-zA-Z0-9]+)/g;

            data.data.movie = data.data.movie.replace('watch?v=', 'embed/'); // watch링크 사용 시 x-frame-options 규칙에 제한 됨에 따라 치환 필요
            
            if(youtubeReg.test(data.data.movie)) data.data.movie = data.data.movie.replace(youtubeReg, "https://www.youtube.com/embed/$2");
            if(vimeoReg.test(data.data.movie)) data.data.movie = data.data.movie.replace(vimeoReg, "https://player.vimeo.com/video/$2");

            this.setState({
                projectTitle: data.data.title,
                videoUrl: data.data.movie,
                projectLink: data.data.url,
            });

            if(this.state.videoUrl) this.setState({selTab: 0});
        }
    }

    // resize event // 헤더 종류 변경
    updateDimensions = () => this.setState({windowWidth: window.innerWidth});
    componentWillMount = () => this.updateDimensions();
    componentDidMount = () => window.addEventListener("resize", this.updateDimensions);
    componentWillUnmount = () => window.removeEventListener("resize", this.updateDimensions);

    // video size 변경(custom size)
    onChangeSize = (idx, width) => {
        if(idx === 0) {
            if(isNaN(width) || !width || width < 220) width = 220;
            // if(isNaN(height) || !height) height = 0;
        }
        this.setState({
            sizeIdx: idx,
            videoWidth: width,
            videoHeight: width / 16 * 9,
        });
    };
    
    // video size 변경(button 클릭)
    onChangeTab = (idx) => this.setState({selTab: idx});

    // 문자열 중 {{}}형태로 되어있는 부분에 데이터 삽입
    sprintf = (template, value) => template.replace(/({{[^{}]*}})/g, () => value.shift());

    render() {
        const { selTab, videoUrl, videoSource, storySource, storyOriginSource, widgetSource, projectLink, sizeIdx, videoWidth, videoHeight, type, id, projectTitle } = this.state;
        const host = `${window.location.protocol}//${window.location.host}/`;

        return (
            <div>
                {(this.state.windowWidth>=769)? (<HeaderLarge/>):(<HeaderMobile title={projectTitle}/>)}
                <main id="embedMain">
                    <div className="embedding_tab-wrap">
                        <ul className="embedding_tab-list">
                            {videoUrl &&
                                <li className={selTab===0 ? 'active' : ''} onClick={() => this.onChangeTab(0)}>
                                    <h2 className="wz text title">동영상</h2>
                                </li>
                            }
                            <li className={selTab===1 ? 'active' : ''} style={!videoUrl ? {borderBottom: '0px', textAlign: 'left', cursor: 'default'} : {}} onClick={() => this.onChangeTab(1)}>
                                <h2 className="wz text title">스토리보드</h2>
                            </li>
                        </ul>
                        {videoUrl &&
                            <EmbedVideo
                                url={videoUrl}
                                sizeIdx={sizeIdx}
                                source={this.sprintf(videoSource, [videoWidth, videoHeight, videoUrl])}
                                sourceOrigin={this.sprintf(videoSource, ['100%', 360, videoUrl])}
                                onChangeSize={this.onChangeSize}
                                active={selTab===0}
                                height={videoHeight}
                            />
                        }
                        <EmbedStory source={this.sprintf(storySource, [686, 1220, host, type, id])} sourceOrigin={this.sprintf(storyOriginSource, ['100%', (this.state.windowWidth>=732) ? 1220 : 881, host, type, id])} active={selTab===1}/>
                    </div>
                    <div className="embedding_widget">
                        <EmbedWidget source={this.sprintf(widgetSource, [host, type, id])}/>
                        <EmbedProjectLink source={projectLink}/>
                    </div>
                </main>
                <Footer active={this.state.footerInfo} onClickFooterInfo={this.onClickFooterInfo}/>
                {/* 
                    토스트 메시지 미사용
                    <Toast/>
                */}
            </div>
        );
    }
}

export default EmbedMain;
