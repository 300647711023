import React, {Component} from 'react';
import Axios from 'axios';
import logo from '../../Assets/Images/wadiz.svg';

import './widget.scss';

class Widget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            type: props.match.params.type,
            title: "",
            summary: "",
            imageUrl: "",
            progress: 0,
            amount: 0,
            restDay: -1,
        };

        this.init();
    }

    async init() {
        const {data} = await Axios.get(`${process.env.REACT_APP_API_HOST}embed/${this.state.type}/v1/${this.state.id}`).then(successData => successData).catch(errorData => errorData);

        if(data) {
            if(!data.data.progress) data.data.progress = 0;
            if(!data.data.amount) data.data.amount = 0;

            if(this.state.type === 'equity') {
                this.setState({
                    title: data.data.title,
                    summary: data.data.summary,
                    imageUrl: data.data.image,
                    progress: data.data.progress,
                    amount: data.data.amount,
                    restDay: data.data.restDay,
                    name: data.data.name,
                    profileImageUrl: data.data.profileImage,
                    url: data.data.url,
                });
            } else if(this.state.type === 'reward') {
                this.setState({
                    title: data.data.title,
                    summary: data.data.summary,
                    imageUrl: data.data.image,
                    progress: data.data.progress,
                    amount: data.data.amount,
                    restDay: data.data.restDay,
                    name: data.data.name,
                    profileImageUrl: data.data.profileImage,
                    url: data.data.url,
                });
            }
        }
    }

    comma = (value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    render() {
        const {title, summary, imageUrl, progress, amount, name, profileImageUrl, url} = this.state;

        return (
            <div className="embedding_widget-embed">
                <div className="embedding_image" style={{backgroundImage: `url(${imageUrl})`}}></div>
                <hr></hr>
                <div className="embedding_border-wrap">
                    <div className="embedding_information">
                        <div className="left">
                            <span className="embedding_progress">
                                {progress}
                                <em>%</em>
                                <em className="embedding_progress-detail">달성</em>
                            </span>
                        </div>
                        <div className="right">
                            {/* <span className="wz basic circular badge mini">증액예정</span> */}
                            {/* <span className="embedding_state">펀딩성공</span> */}
                            <span className="embedding_amount">{this.comma(amount.toString())}원</span>
                        </div>
                    </div>                
                    <div className="embedding_provider">
                        <i className="embedding_icon" style={{backgroundImage: `url(${profileImageUrl})`}}></i>
                        <span>{name}</span>                    
                    </div>
                    <div className="embedding_title"><span>{title}</span></div>
                    <div className="embedding_summary"><span>{summary}</span></div>
                    <button onClick={() => { window.open(url); window.dataLayer.push({ event: 'embed.campaign.shortcut' }); }}>바로가기<i></i></button>
                    {/* <button><a rel="noopener noreferrer" href={url} target="_blank">바로가기 ></a></button> */}
                    <div className="embedding_logo">
                        {/* <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_HOST}><span className="embedding_logo-text">라이프스타일 투자플랫폼</span><img src={logo} alt="wadiz"/></a> */}
                        <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_HOST}><img src={logo} alt="wadiz"/></a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Widget;