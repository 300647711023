import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import { EmbedMain, Widget, Story } from './Pages';
import './App.scss';
import './notosans.scss';
import './notoserif.scss';
import './roboto.scss';

function App() {
  return (
    <BrowserRouter>
        {/* <Route exact path="/" component={ DummyMain }></Route> */}
        <Route exact path="/widget/:type/:id" component={ Widget }></Route>
        <Route exact path="/story/:type/:id" component={ Story }></Route>
        <Route exact path="/:type/:id" component={ EmbedMain }></Route>
    </BrowserRouter>
  );
}

export default App;