import React, {Component} from 'react';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMore: false,
        };
    }

    onClickMore = () => this.setState({isMore: !this.state.isMore});

    render() {
        return (
            <div className="web-header">
                <div className="web-header-large">
                    <header className="header-wrapper">
                        <div className="header-container">
                            <h1 className="wadiz-logo">
                                <span className="label">와디즈</span>
                                <a href="https://wadiz.kr/">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="85" height="25" viewBox="0 0 85 25">
                                        <path fill="#1D2129" fillRule="nonzero" d="M58.49 2.23h2.11v21.29H59a1.73 1.73 0 0 1-1.62-1.16 9 9 0 0 1-10.93-.28 7.48 7.48 0 0 1-2.75-6.29 7.65 7.65 0 0 1 12.79-5.07V4.23a2 2 0 0 1 2-2zm-6.3 18.62a4.36 4.36 0 0 0 4.16-4.52 4.19 4.19 0 1 0-8.35 0 4.36 4.36 0 0 0 4.19 4.52zM42 23.52h-1.52a1.73 1.73 0 0 1-1.64-1.16 9 9 0 0 1-10.93-.28 7.48 7.48 0 0 1-2.75-6.29A7.65 7.65 0 0 1 38 10.72a2 2 0 0 1 1.9-1.79H42v14.59zm-8.41-2.67h.02a4.35 4.35 0 0 0 4.15-4.52 4.35 4.35 0 0 0-4.17-4.51 4.35 4.35 0 0 0-4.17 4.51 4.36 4.36 0 0 0 4.17 4.52zM83.47 8.94v2.11l-7.07 9.06h7.06v3.47h-13v-2.1l7.08-9.07h-6.62v-1.47a2 2 0 0 1 2-2h10.55zM65.86 7.3a2.48 2.48 0 1 1 0-4.96 2.48 2.48 0 0 1 0 4.96zM21.21 8.94h4.14l-4.86 14.59h-4.15l-3.21-9.36-3.21 9.36H5.77L.92 8.94h4.13L8 18l2.5-7.74a2 2 0 0 1 1.86-1.34h2.9l3 9.08 2.95-9.06zm42.65 14.59l-.04-12.59a2 2 0 0 1 2-2h2.11v14.59h-4.07z"></path>
                                    </svg>
                                </a>
                            </h1>
                            <ul className="gnb-large">
                                <li><a className="item" href="https://wadiz.kr/web/winvest/main"><span>투자</span></a></li>
                                <li><a className="item" href="https://wadiz.kr/web/wreward/main"><span>리워드</span></a></li>
                                <li>
                                    <div>
                                        <button className={"GNBDesktop_btnMore__33OFY" + (this.state.isMore ? " GNBDesktop_active__2PeQs" : "")} onClick={this.onClickMore}>더보기<i className="icon chevron-right" aria-hidden="true"></i></button>
                                        <div className={"MoreMenuDesktop_container__104wM" + (this.state.isMore ? " MoreMenuDesktop_active__3dqED" : "")}>
                                            <div className="MoreMenuDesktop_innerContainer__2RwMO">
                                                <div className="MenuList_container__3ofD- MoreMenuDesktop_moreMenuList__11cPX">
                                                    <h4 className="MenuList_label__qSsts">바로가기</h4>
                                                    <button className="MenuListItem_button__8-ecU">펀딩 오픈 신청하기<i className="icon chevron-right" aria-hidden="true"></i></button>
                                                    <button className="MenuListItem_button__8-ecU">메이커 뉴스레터<i className="icon chevron-right" aria-hidden="true"></i></button>
                                                    <button className="MenuListItem_button__8-ecU">와디즈 스쿨<i className="icon chevron-right" aria-hidden="true"></i></button>
                                                    <button className="MenuListItem_button__8-ecU">캐스트<i className="icon chevron-right" aria-hidden="true"></i></button>
                                                    <button className="MenuListItem_button__8-ecU">이용 가이드<i className="icon chevron-right" aria-hidden="true"></i></button>
                                                </div>
                                                <div className="MenuList_container__3ofD- MoreMenuDesktop_moreMenuList__11cPX">
                                                    <h4 className="MenuList_label__qSsts">와디즈 소개</h4>
                                                    <button className="MenuListItem_button__8-ecU">와디즈 소개<i className="icon chevron-right" aria-hidden="true"></i></button>
                                                    <button className="MenuListItem_button__8-ecU">와디즈 마스터<i className="icon chevron-right" aria-hidden="true"></i></button>
                                                    <button className="MenuListItem_button__8-ecU">와디즈 파트너<i className="icon chevron-right" aria-hidden="true"></i></button>
                                                    <button className="MenuListItem_button__8-ecU">성공 프로젝트<i className="icon chevron-right" aria-hidden="true"></i></button>
                                                </div>
                                                <div className="MenuList_container__3ofD- MoreMenuDesktop_moreMenuList__11cPX">
                                                    <h4 className="MenuList_label__qSsts">공지</h4>
                                                    <button className="MenuListItem_button__8-ecU">공지사항<i className="icon chevron-right" aria-hidden="true"></i></button>
                                                    <button className="MenuListItem_button__8-ecU">이벤트<i className="icon chevron-right" aria-hidden="true"></i></button>
                                                    <button className="MenuListItem_button__8-ecU">채용<i className="icon chevron-right" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"Backdrop_backDrop__2cxxQ Backdrop_dimmed__L5zbD GNBDesktop_backdrop__3shzy" + (this.state.isMore ? " Backdrop_active__3qNj6" : "")} onClick={this.onClickMore}></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </header>
                </div>
            </div>
        );
    }
}

export default Header;
